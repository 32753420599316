import { render, staticRenderFns } from "./ProjectLocation.vue?vue&type=template&id=96b4250a&scoped=true"
var script = {}
import style0 from "./ProjectLocation.vue?vue&type=style&index=0&id=96b4250a&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "96b4250a",
  null
  
)

export default component.exports